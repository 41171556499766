/* eslint-disable react/jsx-no-useless-fragment */
import classnames from 'classnames';
import {
    memo,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { CSSTransition } from 'react-transition-group';
import { useDebouncedCallback } from 'use-debounce';
import { instrumenter } from 'client/utils/instrumentation';
import { resolveSrc, resolveSrcSet } from 'client/components/common/McpImage/utils';
import { useCallbackRef } from 'client/hooks/useRef';
import { EagerLoadingImage } from 'client/components/common/McpImage/EagerLoadingImage';
import { generateSrcSetWithoutCustomImages } from 'src/client/components/Gallery/Header/Personalization/utils';
import { Spinner } from '@vp/swan';
import { useTranslations } from 'client/hooks/useTranslations';
import { getLogger } from '~/client/utils/gallery/logger';
import { FallbackPreview } from 'client/components/common/McpImage/FallbackPreview';

export interface McpImageProps extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'>, Omit<Partial<Gallery.Designs.RenderablePreviewInfo>, 'previewInfo' | 'aspectRatio'> {
    isLoading?: boolean;
    loading?: 'lazy' | 'eager';
    // If set, log a metric for the load time of the image, as well on image error
    metricImageType?: string;
    // If set, override src and srcSet, ignoring any values passed in.
    srcInfo?: Gallery.Designs.McpImageSrcInfo;
    srcSet?: string;
    bypassedApproval?: Gallery.Models.Url.ValidParsedQsValue<boolean>;
    maxPreviewHeight: [number, string | false];
    size?: 'default' | 'large';
}

const LOGGER = getLogger();

export const McpImage = memo((props: McpImageProps): JSX.Element => {
    const {
        alt,
        className,
        maxPreviewHeight,
        isLoading: isLoadingProp,
        loading = 'lazy',
        metricImageType,
        srcInfo,
        srcSet,
        width,
        bypassedApproval,
        size,
        ...rest
    } = props;

    const [currentSrcSet, setCurrentSrcSet] = useState<string | undefined>(resolveSrcSet(srcInfo, srcSet));
    const [hasError, setHasError] = useState<boolean>(false);
    const [isImgLoading, setIsImgLoading] = useState<boolean>(loading === 'lazy');
    const [showLoader, setShowLoader] = useState<boolean>(true);
    const imgRef = useCallbackRef<HTMLImageElement>(null, (current) => {
        setIsImgLoading(!current?.complete);
    });
    const localize = useTranslations();

    const currentSrc = resolveSrc(srcInfo?.size2x);

    // Delay by 100ms to prevent loader from briefly appearing
    const debouncedShowLoader = useDebouncedCallback(() => {
        setShowLoader(isImgLoading);
    }, 100);

    useEffect(() => {
        const newSrcSet = resolveSrcSet(srcInfo, srcSet);

        if (newSrcSet !== currentSrcSet) {
            try {
                if (currentSrcSet !== undefined && currentSrcSet === generateSrcSetWithoutCustomImages(newSrcSet)) {
                    return;
                }
            } catch (e) {
                LOGGER.error(`Error reacting to src changes: ${e}`, e as Error, { currentSrcSet, newSrcSet });
                // If an error occurs, don't attempt to update to the new src set
                return;
            }

            setHasError(false);
            setIsImgLoading(true);
            setCurrentSrcSet(newSrcSet);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [srcInfo, srcSet]);

    useEffect(() => {
        if (isImgLoading) {
            debouncedShowLoader();
        } else {
            setShowLoader(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isImgLoading]);

    const handleError = useCallback((): void => {
        // Do not log image failures when the query string bypassApproval is true
        if (!bypassedApproval) {
            instrumenter.recordImageFailure({
                srcSet,
                ...srcInfo,
            });
        }

        const newSrcSet = generateSrcSetWithoutCustomImages(currentSrcSet);

        if (newSrcSet) {
            setCurrentSrcSet(newSrcSet);
        }

        // checking for newSrcSet here prevents the blue triangle from being rendered and causing tiles to change height
        // newSrcSet should always be undefined outside the Photo Personalization test
        setHasError(!newSrcSet);
        setIsImgLoading(false);
    }, [bypassedApproval, currentSrcSet, srcSet, srcInfo]);

    const handleLoad = (): void => {
        setHasError(false);
        setIsImgLoading(false);
    };

    const isLoading = !!showLoader || !!isLoadingProp;
    // Always use the set number height, but if we're loading with no dimensions yet, use a base min
    const height = isLoading ? (maxPreviewHeight[0] ?? 165) : maxPreviewHeight[0];

    const shouldRenderSpinner = (loading === 'lazy' && isLoading) || (loading === 'eager' && isLoading && isImgLoading);

    return (
        <>
            {shouldRenderSpinner && (
                <div style={{ maxHeight: height }}>
                    <div className="mcp-img-loading">
                        <Spinner accessibleText={localize('Loading')} size="standard" />
                    </div>
                </div>
            )}
            <span className={classnames('mcp-image-span', 'tile-preview-image')} hidden={loading === 'eager' && !!isLoading}>
                <CSSTransition
                    appear
                    mountOnEnter
                    unmountOnExit
                    classNames="fade"
                    in={hasError}
                    key={currentSrcSet}
                    timeout={500}
                >
                    <FallbackPreview size={size} />
                </CSSTransition>
                <CSSTransition
                    appear
                    classNames="fade"
                    in={!isLoading && !hasError}
                    timeout={500}
                >
                    <img
                        {...rest}
                        alt={alt}
                        className={classnames('mcp-image', className)}
                        crossOrigin="anonymous"
                        height={height}
                        loading={loading}
                        ref={imgRef}
                        src={currentSrc}
                        srcSet={currentSrcSet}
                        width={width}
                        onError={handleError}
                        onLoad={handleLoad}
                    />
                </CSSTransition>
            </span>
            {loading === 'eager' && !!isLoading && (
                <EagerLoadingImage
                    className="tile-preview-image"
                    maxPreviewHeight={maxPreviewHeight}
                    srcInfo={srcInfo}
                    width={width}
                    {...rest}
                />
            )}
        </>
    );
});

McpImage.displayName = 'McpImage';
