import { useContext } from 'react';

import { formatString } from 'shared/localization';
import { IntlContext } from 'client/contexts/IntlContext';

/**
 * Gets the localized string for the given key
 *
 * @param {object} messages - object containing the localized strings
 * @param {string} key - key for which to get the localized string
 * @param {object?} replacements - replaces tokens in messages <token /> (by default) with the value from the object
 * @param {object?} interpolation - prefix/suffix overrides for string interpolation
 */
function getTranslationsFactory(messages: Util.StringDictionary<string>): i18n.Localize {
    return function getTranslation(
        key: string,
        replacements?: Util.StringDictionary<string>,
        interpolation?: { prefix: string, suffix: string },
    ): string {
        const message = messages[key];

        if (message === undefined) {
            throw new ReferenceError(`Could not find Intl message: ${key}`);
        }

        return formatString(message, replacements, undefined, interpolation);
    };
}

export const useTranslations = (): i18n.Localize => {
    const { messages } = useContext(IntlContext);

    return getTranslationsFactory(messages);
};
