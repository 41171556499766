import qs from 'qs';
import config from 'config';

import { AbstractService } from 'services/AbstractService';
import { ServiceError, InvalidResponseError } from 'services/errors';
import { TEMPLATE_PURPOSES } from '~/shared/constants';

export type ContentQuery = {
    galleryName: string;
    bypassApproval: Gallery.Models.Url.ValidParsedQsValue<boolean>;
    locale: i18n.Locale;
    mpvId: Gallery.Models.Url.ValidParsedQsValue<string>;
    offset?: number;
    length?: number;
    attributes?: string[][];
    categories?: string[][];
    templateUseCases?: string[][];
    facetCategories?: string[];
    facetTemplateUseCases?: string[];
    keyword?: string[];
    collection?: string;
    selectedOptions?: Record<string, any>;
    designIds?: string[];
    designPersonalizationContext?: Gallery.Models.Personalization.DesignPersonalizationContext;
    noCache: Gallery.Models.Url.ValidParsedQsValue<boolean>;
    useConstraints: Gallery.Models.Url.ValidParsedQsValue<boolean>,
    rankingStrategyOverride: Gallery.Models.Url.ValidParsedQsValue<string>;
    forcedRankingStrategyOverride: Gallery.Models.Url.ValidParsedQsValue<string>;
    templateTokenFeatureFlags?: string[];
    renderProps: Record<string, string | null | undefined>;
    templatePurposes?: TEMPLATE_PURPOSES[];
    imagePlaceholderAspectRatio?: Gallery.Models.Url.ValidParsedQsValue<number>,
    imagePlaceholderAspectRatioTolerance?: Gallery.Models.Url.ValidParsedQsValue<number>,
    placeholderPurposes?: Gallery.Models.Url.ValidParsedQsValue<string[]>,
    searchBackend?: Gallery.Models.Url.ValidParsedQsValue<string>,
    useNewColorPalette?: Gallery.Models.Url.ValidParsedQsValue<boolean>,
    experimentFlags: Record<string, string>;
    configExperimentFlags: string[];
    designCreationTypes?: Gallery.Models.Url.ValidParsedQsValue<string[]>,
    useRealisationEngineService?: boolean;
    consolidateContent?: boolean;
};

const DEFAULT_CONTENT_QUERY = {} as ContentQuery;

export function calculateOffset(page: number, pageSize: number): number {
    return (page - 1) * pageSize;
}

export class ContentService extends AbstractService implements Services.IContentService {
    /**
     * Given a ContentQuery object, request new content from the ContentService
     *
     * @param query
     * @throws ServiceError
     */
    public async getContent(query: ContentQuery = DEFAULT_CONTENT_QUERY): Promise<Gallery.ContentQuery.Response> {
        const {
            attributes = [],
            bypassApproval,
            categories = [],
            templateUseCases = [],
            locale,
            facetCategories,
            useRealisationEngineService,
            galleryName,
            keyword,
            collection,
            length,
            offset,
            selectedOptions,
            noCache,
            useConstraints,
            rankingStrategyOverride,
            forcedRankingStrategyOverride,
            designIds,
            templateTokenFeatureFlags,
            renderProps,
            templatePurposes,
            imagePlaceholderAspectRatio,
            imagePlaceholderAspectRatioTolerance,
            placeholderPurposes,
            searchBackend,
            useNewColorPalette,
            experimentFlags,
            configExperimentFlags,
            designCreationTypes,
            designPersonalizationContext,
            consolidateContent,
            ...rest
        } = query;

        if (!galleryName) {
            throw new Error('Parameter "galleryName" must be provided');
        }

        const url = `v2/Galleries/${encodeURIComponent(galleryName)}/Culture/${locale}/Content`;

        try {
            const response = await this.api.get<Gallery.ContentQuery.Response>(url, {
                params: {
                    attributes: attributes.map(((attributeItem) => attributeItem.join(','))),
                    bypassApproval,
                    categories: categories.map(((categoryItem) => categoryItem.join(','))),
                    templateUseCases: templateUseCases.flat(),
                    facetCategories,
                    useRealisationEngineService,
                    keywords: [keyword],
                    collection,
                    limit: length,
                    offset,
                    requestor: noCache ? config.noCacheRequestor : config.appName,
                    noCache,
                    useConstraints,
                    rankingStrategyOverride,
                    forcedRankingStrategyOverride,
                    selectedOptions,
                    designIds: designIds?.join(','),
                    templateTokenFeatureFlags,
                    renderProps,
                    templatePurposes,
                    imagePlaceholderAspectRatio,
                    imagePlaceholderAspectRatioTolerance,
                    placeholderPurposes,
                    searchBackend,
                    useNewColorPalette,
                    experimentFlags,
                    configExperimentFlags,
                    designPersonalizationContext: JSON.stringify(designPersonalizationContext),
                    designCreationTypes,
                    consolidateContent,
                    ...rest,
                },
                paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
            });

            if (!response || !response.data || typeof response.data === 'string') {
                throw new InvalidResponseError({
                    message: `Empty Response from content query. Status: ${response?.status}`,
                    query,
                    response,
                    url,
                });
            }

            // don't log a warning for an empty keyword search
            if (!keyword && response.data.results.content.length <= 0) {
                this.logger.warning(new ServiceError({
                    url,
                    message: 'Zero content returned from content API',
                    query,
                }));
            }

            const resp = response.data;

            // putting this here for debugging, it's bad, I know.
            resp.requestUrl = url;
            return resp;
        } catch (e) {
            if ((e as Error).name === 'InvalidResponseError') {
                throw e;
            }

            throw new ServiceError({
                url,
                message: `Bad response from content query service: ${(e as Error).message}`,
                query,
            }, e as Error);
        }
    }
}
