import {
    ComponentProps,
    memo,
    MouseEvent,
} from 'react';
import classnames from 'classnames';
import {
    ColorSwatch,
    ColorSwatches,
    ColorSwatchesMore,
    SelectionSet,
    SelectionSetInput,
    SelectionSetLabel,
} from '@vp/swan';
import { useTranslations } from 'client/hooks/useTranslations';
import { ShowMoreButton } from 'client/components/common/ShowMoreButton';
import { hash } from 'client/utils/hash';
import { i18nColorName } from '@design-stack-vista/i18n-color-name';
import { useColorSwatchesParameters } from '~/client/components/common/ColorSwatchPicker/hooks/useColorSwatchesParameters';

interface PropsType {
    ariaLabelledBy?: string;
    collapse?: boolean;
    colorSwatches: Gallery.ContentQuery.ColorSwatch[];
    colorSwatchesProps?: Partial<ComponentProps<typeof ColorSwatches>>;
    entityId?: string;
    onLabelClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    onColorSwatchChange: (selectedValue: string) => void;
    selectedDesignId: string;
    locale: string;
    title?: () => React.ReactNode;
    shouldShowMerchModules?: boolean;
}

function getRegionIdBasedOnColors(colorSwatches: Gallery.ContentQuery.ColorSwatch[]): string {
    return hash(colorSwatches.map((colorSwatch) => colorSwatch.designId).join(''));
}

export const ColorSwatchPicker = memo((props: PropsType): JSX.Element => {
    const {
        ariaLabelledBy,
        collapse = false,
        colorSwatches,
        colorSwatchesProps = {},
        entityId = null,
        onLabelClick,
        onColorSwatchChange,
        selectedDesignId,
        locale,
        title,
        shouldShowMerchModules,
    } = props;

    const localize = useTranslations();

    // If props.entityId is not provided, hash the available color strings.
    const colorRegionId = `colors-${entityId || getRegionIdBasedOnColors(colorSwatches)}`;

    const {
        shouldShowMore,
        visibleSwatches,
        shouldShowMoreMobile,
        shouldHideTwoLastItems,
        shouldHideLastItem,
        colorSwatchesLength,
        setShouldShowMore,
        mobileMaxColorSwatchesToShow,
        shouldShowMoreButton,
    } = useColorSwatchesParameters(collapse, colorSwatches, shouldShowMerchModules);

    const toggleShowMore = (): void => setShouldShowMore(!shouldShowMore);

    const onColorSwatchChangeWrapper = (selectedValue: string | number | null): void => {
        const stringifiedValue = selectedValue?.toString() || '';

        onColorSwatchChange(stringifiedValue);
    };

    return (
        <>
            {(colorSwatches.length <= 1) && null}
            {(colorSwatches.length > 1) && (
                <div
                    aria-labelledby={ariaLabelledBy}
                    className={classnames(
                        'color-swatch-picker',
                        {
                            'hide-last-child': shouldShowMoreMobile,
                            'merch-color-swatch-picker': shouldShowMerchModules,
                            'hide-two-last-items': shouldHideTwoLastItems,
                            'hide-last-item': shouldHideLastItem,
                        },
                    )}
                >
                    { title && title()}
                    <SelectionSet
                        aria-label={localize('ColorSwatchGroupLabel')}
                        selectedValue={selectedDesignId}
                        variant="single-select"
                        onSelectedValueChange={onColorSwatchChangeWrapper}
                    >
                        <ColorSwatches id={colorRegionId} {...colorSwatchesProps}>
                            {visibleSwatches.map((colorSwatch) => {
                                const { color, designId } = colorSwatch;
                                const colorName = color ? i18nColorName(color, { culture: locale, colorPalette: 'gallery' }) : undefined;

                                return (
                                    <SelectionSetInput
                                        aria-label={colorName}
                                        key={designId}
                                        value={designId}
                                    >
                                        <SelectionSetLabel>
                                            <ColorSwatch
                                                as="span"
                                                primaryColor={color}
                                                title={colorName}
                                            >
                                                {colorName && <span className="screen-reader-text">{colorName}</span>}
                                            </ColorSwatch>
                                        </SelectionSetLabel>
                                    </SelectionSetInput>
                                );
                            })}
                        </ColorSwatches>
                    </SelectionSet>
                    {shouldShowMoreMobile && (
                        <ColorSwatchesMore className="show-more-badge mobile" onClick={onLabelClick}>
                            {`+${colorSwatches.length - mobileMaxColorSwatchesToShow}`}
                        </ColorSwatchesMore>
                    )}
                    {shouldShowMoreButton && (
                        <ShowMoreButton
                            active={shouldShowMore}
                            aria-controls={colorRegionId}
                            aria-expanded={shouldShowMore}
                            aria-label={localize('ColorSwatchShowMoreAriaLabel')}
                            className={classnames('tablet desktop', { 'disable-click': shouldShowMerchModules })}
                            shoulHideMoreArrowIcon={shouldShowMerchModules}
                            onClick={toggleShowMore}
                        >
                            {`+ ${colorSwatchesLength}`}
                        </ShowMoreButton>
                    )}
                </div>
            )}
        </>
    );
});

ColorSwatchPicker.displayName = 'ColorSwatchPicker';
