import HttpAgent, { HttpsAgent } from 'agentkeepalive';
import config from 'config';
import debug, { Debugger } from 'debug';
import axios, {
    AxiosInstance,
    AxiosRequestConfig,
} from 'axios';
import axiosRetry from 'axios-retry';
import { setupCache } from 'axios-cache-interceptor';

import { Logger } from '@vp/js-logger';

export type LoggerParameter = (() => Gallery.Logger.ILogger) | Gallery.Logger.ILogger;

// Creating this type here since we can't import in definition files
export type AbstractServiceConfig = Gallery.Config.IServiceConfig & AxiosRequestConfig;

const GLOBAL_CONFIG = config.services.global;

export class AbstractService {
    api: AxiosInstance;

    config: AbstractServiceConfig;

    debug: Debugger;

    loggerOrProvider: LoggerParameter;

    constructor(serviceConfig: AbstractServiceConfig, logger: LoggerParameter) {
        const keepaliveConfig = {
            ...GLOBAL_CONFIG.keepalive,
            ...serviceConfig.keepalive,
        };

        this.api = axios.create({
            httpAgent: new HttpAgent(keepaliveConfig),
            httpsAgent: new HttpsAgent(keepaliveConfig),
            ...serviceConfig,
        });

        this.config = serviceConfig;
        this.debug = debug(`${config.debug.namespace}:${this.constructor.name}`);
        this.loggerOrProvider = logger;

        this.decorateWithDebugging(this.api);
        this.attachRetryHandler(this.api);

        if (serviceConfig.caching?.staleAge) {
            this.api = setupCache(this.api, {
                ttl: serviceConfig.caching.staleAge * 1000, // in milliseconds
                // this value will only be used if the interpreter can’t determine TTL value from response headers
            });
        }
    }

    get logger(): Logger {
        return typeof this.loggerOrProvider === 'function' ? this.loggerOrProvider() : this.loggerOrProvider;
    }

    attachRetryHandler(instance: AxiosInstance): void {
        const retries = this.config.retry?.retries || GLOBAL_CONFIG.retry?.retries || 3;

        axiosRetry(instance, {
            retries,
            retryDelay: (retryCount) => axiosRetry.exponentialDelay(retryCount),
        });
    }

    decorateWithDebugging(instance: AxiosInstance): void {
        instance.interceptors.request.use((requestConfig) => {
            const requestDebugger = this.debug.extend('request');

            requestDebugger({ config, request: requestConfig.url });

            return requestConfig;
        });

        instance.interceptors.response.use((response) => {
            // TODO this would be a good place to support the DEBUG=true cookie as well
            const requestDebugger = this.debug.extend('response');

            requestDebugger({ response });

            return response;
        });
    }
}
