import config from 'config';
import qs from 'query-string';

export class PersonalizedUrlBuilder {
    public static buildDssInstructionsUri(
        locale: string,
        templateToken: string,
        productKey: string,
        productVersion: number,
        optionSelections: Record<string, string>,
        personalizedText: Util.StringDictionary<string>,
        photoPreviewData: Gallery.Models.Personalization.CustomImageData[],
    ): string {
        const dssUri = PersonalizedUrlBuilder.buildDssUri(
            locale,
            templateToken,
            productKey,
            productVersion,
            optionSelections,
            photoPreviewData,
        );

        const personalizedUri = PersonalizedUrlBuilder.buildTextPersonalizationUri(
            dssUri,
            personalizedText,
            true,
        );

        return PersonalizedUrlBuilder.buildInstructionsUri(personalizedUri);
    }

    public static buildDiamondUri(
        templateToken: string,
        designPersonalizationContext: Gallery.Models.Personalization.DesignPersonalizationContext,
        culture: string,
        productId: string,
        productOptions: Record<string, string>,
        productVersion: number,
    ): string {
        const params = {
            culture,
            productId,
            productVersion,
            productOptions: qs.stringify(productOptions),
            designPersonalizationContext: JSON.stringify(designPersonalizationContext),
            requestor: config.appName,
        };

        return `${config.client.rendering.diamondURL}/v0/templates/${templateToken}/renderableDocument?${qs.stringify(params)}`;
    }

    public static buildDiamondInstructionsUri(
        templateToken: string,
        designPersonalizationContext: Gallery.Models.Personalization.DesignPersonalizationContext,
        culture: string,
        productId: string,
        productOptions: Record<string, string>,
        productVersion: number,
    ): string {
        const diamondUri = this.buildDiamondUri(
            templateToken,
            designPersonalizationContext,
            culture,
            productId,
            productOptions,
            productVersion,
        );

        return this.buildInstructionsUri(diamondUri);
    }

    public static buildTextPersonalizationUri(
        realizationUri: string,
        personalizedText: Util.StringDictionary<string>,
        copyPlaceholders = false,
    ): string {
        if (Object.keys(personalizedText).length) {
            const personalizedTextObj = Object.entries(personalizedText).reduce((newObj, [key, value]) => {
                // eslint-disable-next-line no-param-reassign
                newObj[`personalizedText[${key}]`] = value;

                return newObj;
            }, {} as Util.StringDictionary<string>);

            const editDocUrl = new URL(realizationUri);

            editDocUrl.searchParams.set('templateDeliveryMode', 'editDoc');

            const params = {
                documentUrl: editDocUrl.toString(),
                copyPlaceholders,
                ...personalizedTextObj,
            };

            return `${config.client.rendering.galleryPersonalizationURL}?${qs.stringify(params)}`;
        }

        return realizationUri;
    }

    public static buildDiamondStudioUrl = (
        instructions: string,
        studioUrl: URL,
    ): URL => {
        const diamondUrlString = new URL(instructions).searchParams.get('documentUri');

        if (!diamondUrlString) {
            return studioUrl;
        }

        const diamondUrl = new URL(diamondUrlString);
        const updatedUrl = studioUrl;
        const path = diamondUrl.pathname.split('/');

        // Safely replace renderableDocument in the path with editableDocument for Studio use
        path[path.indexOf('renderableDocument')] = 'editableDocument';
        diamondUrl.pathname = path.join('/');

        updatedUrl.searchParams.set('documentUrl', diamondUrl.toString());

        return updatedUrl;
    };

    public static createPersonalizedStudioUrl = (
        instructions: string,
        studioUrl: URL,
    ): URL => {
        const personalizedDocumentUriString = new URL(instructions).searchParams.get('documentUri');

        if (personalizedDocumentUriString) {
            const updatedUrl = new URL(studioUrl);

            // strip off the queryString values that we don't need
            updatedUrl.searchParams.delete('template');
            updatedUrl.searchParams.delete('previewUrl');

            const personalizedDocumentUri = new URL(personalizedDocumentUriString);

            personalizedDocumentUri.searchParams.delete('copyPlaceholders');

            updatedUrl.searchParams.set('documentUrl', personalizedDocumentUri.toString());

            return updatedUrl;
        }

        return studioUrl;
    };

    private static buildDssUri(
        locale: string,
        templateToken: string,
        productKey: string,
        productVersion: number,
        optionSelections: Record<string, string>,
        photoPreviewData: Gallery.Models.Personalization.CustomImageData[],
    ): string {
        const optionSelectionsObj = Object.entries(optionSelections).reduce((newObj, [key, value]) => {
            // eslint-disable-next-line no-param-reassign
            newObj[`optionSelections[${key}]`] = value;

            return newObj;
        }, {} as Util.StringDictionary<string>);

        const params = {
            // query-string does not stringify undefined attributes, so if
            // backgroundColor is null (the background is supposed to be
            // transparent), set the value to undefined
            culture: locale,
            fullbleed: false,
            templateToken,
            colorMode: 'print',
            requester: config.appName,
            customImages: photoPreviewData.map((previewData) => JSON.stringify(previewData)),
            ...optionSelectionsObj,
        };

        return `${config.client.rendering.designSpecificationsURL}/v2/designDocuments/products/${
            productKey
        }/${
            productVersion
        }?${
            qs.stringify(params)
        }`;
    }

    private static buildInstructionsUri(realizationUri: string): string {
        const params = {
            documentUri: realizationUri,
            type: 'preview',
        };

        return `${config.client.rendering.udsBaseURL}/v2/transient/vp?${qs.stringify(params)}`;
    }
}
